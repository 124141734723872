/* html,
body {
  height: 100%;
}
body {
  margin: 0;
  background: -webkit-linear-gradient(45deg, #49a09d, #5f2c82);
  background: linear-gradient(45deg, #49a09d, #5f2c82);
  font-family: sans-serif;
  font-weight: 100;
}
.container {
  position: absolute;
  top: 50%;
  left: 50%;
  max-height: 600px;
  overflow-y: scroll;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
} */

table {
  border-collapse: collapse;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  /*background-color: #c4c9dd;*/
  border-radius: 10px;
  margin-top: 5%;
}

th {
  text-align: left;
}  

thead{
  background-color: #36659d;
  color: white;
  text-align: center;
}

tbody {
  text-align: center;
}

tbody tr:hover {
  background-color: rgb(224, 224, 224);
}

tbody td {
  position: relative;
}

/* th, td {
  background-color: rgba(255, 255, 255, 0.2); 
  padding: 15px;
} */

/* tbody td:hover:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: -9999px;
  bottom: -9999px;
  background-color: rgba(255, 255, 255, 0.2);
  z-index: -1;
} */