.App {
  width: 100%;
  /*height: 100%;*/
  position: absolute;
  background-color: #4277a3;
  background-image: url("https://i.imgur.com/KhcPCVM.jpeg");
  -webkit-animation: infiniteScrollBg 35s linear infinite;
          animation: infiniteScrollBg 35s linear infinite;
}

@-webkit-keyframes infiniteScrollBg {
  0% {
    background-position: 50% 0;
  }
  100% {
    background-position: 50% -808px;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@keyframes infiniteScrollBg {
  0% {
    background-position: 50% 0;
  }
  100% {
    background-position: 50% -808px;
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
