:root {
  --primary-color: #4EA685;
  --secondary-color: #57B894;
  --black: #000000;
  --white: #ffffff;
  --gray: #efefef;
  --gray-2: #757575;

  --facebook-color: #4267B2;
  --google-color: #DB4437;
  --twitter-color: #1DA1F2;
  --insta-color: #E1306C;
}

@import url('https://fonts.login_googleapis.login_com/css2?family=Poppins:wght@200;300;400;500;600&display=swap');

.login_translate__{
  translate: 650px;
}

.recover_password_bg{
  background: rgb(10,0,185);
  background: linear-gradient(90deg, rgba(10,0,185,1) 0%, rgba(22,105,192,1) 35%, rgba(12,214,255,1) 100%);
}


* {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.login_container {
  position: relative;
  min-height: 100vh;
  overflow: hidden;
}

.login_row {
  display: flex;
  flex-wrap: wrap;
  height: 100vh;
}

.login_col {
  width: 50%;
}

.login_align-items-center {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.login_form-wrapper {
  width: 100%;
  max-width: 28rem;
}

.login_form {
  padding: 1rem;
  background-color: var(--white);
  border-radius: 1.5rem;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transform: scale(0);
  transition: .5s ease-in-out;
  transition-delay: 1s;
}

.login_input-group {
  position: relative;
  width: 100%;
  margin: 1rem 0;
}

.login_input-group i {
  position: absolute;
  top: 50%;
  left: 1rem;
  transform: translateY(-50%);
  font-size: 1.4rem;
  color: var(--gray-2);
}

.login_input-group input {
  width: 100%;
  padding: 1rem 3rem;
  font-size: 1rem;
  background-color: var(--gray);
  border-radius: .5rem;
  border: 0.125rem solid var(--white);
  outline: none;
}

.login_input-group input:focus {
  border: 0.125rem solid var(--primary-color);
}

.login_form button {
  cursor: pointer;
  width: 100%;
  padding: .6rem 0;
  border-radius: .5rem;
  border: none;
  background-color: #0a58ca;
  color: var(--white);
  font-size: 1.2rem;
  outline: none;
}

.showdisplay{
  display:block;
  transition: all 1s ease
 
}

.showdisplay:active{
  display: block;
}

.login_form p {
  margin: 1rem 0;
  font-size: .7rem;
}

.login_flex-col {
  flex-direction: column;
}

.login_social-list {
  margin: 2rem 0;
  padding: 1rem;
  border-radius: 1.5rem;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transform: scale(0);
  transition: .5s ease-in-out;
  transition-delay: 1.2s;
}

.login_social-list>div {
  color: var(--white);
  margin: 0 .5rem;
  padding: .7rem;
  cursor: pointer;
  border-radius: .5rem;
  cursor: pointer;
  transform: scale(0);
  transition: .5s ease-in-out;
}

.login_social-list>div:nth-child(1) {
  transition-delay: 1.4s;
}

.login_social-list>div:nth-child(2) {
  transition-delay: 1.6s;
}

.login_social-list>div:nth-child(3) {
  transition-delay: 1.8s;
}

.login_social-list>div:nth-child(4) {
  transition-delay: 2s;
}

.login_social-list>div>i {
  font-size: 1.5rem;
  transition: .4s ease-in-out;
}

.login_social-list>div:hover i {
  transform: scale(1.5);
}

.login_facebook-bg {
  background-color: var(--facebook-color);
}

.login_google-bg {
  background-color: var(--google-color);
}

.login_twitter-bg {
  background-color: var(--twitter-color);
}

.login_insta-bg {
  background-color: var(--insta-color);
}

.pointer {
  cursor: pointer;
}

.login_container.login_sign-in .login_form.login_sign-in,
.login_container.login_sign-in .login_social-list.login_sign-in,
.login_container.login_sign-in .login_social-list.login_sign-in>div,
.login_container.login_sign-up .login_form.login_sign-up,
.login_container.login_sign-up .login_social-list.login_sign-up,
.login_container.login_sign-up .login_social-list.login_sign-up>div {
  transform: scale(1);
}

.login_content-row {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 6;
  width: 100%;
}

.login_text {
  margin: 4rem;
  color: var(--white);
}

.login_text h2 {
  font-size: 3.5rem;
  font-weight: 800;
  margin: 2rem 0;
  transition: 1s ease-in-out;
}

.login_text p {
  font-weight: 600;
  transition: 1s ease-in-out;
  transition-delay: .2s;
}

.login_img img {
  width: 30vw;
  transition: 1s ease-in-out;
  transition-delay: .4s;
}

.login_text.login_sign-in h2,
.login_text.login_sign-in p,
.login_img.login_sign-in img {
  transform: translateX(-250%);
}

.login_text.login_sign-up h2,
.login_text.login_sign-up p,
.login_img.login_sign-up img {
  transform: translateX(250%);
}

.login_container.login_sign-in .login_text.login_sign-in h2,
.login_container.login_sign-in .login_text.login_sign-in p,
.login_container.login_sign-in .login_img.login_sign-in img,
.login_container.login_sign-up .login_text.login_sign-up h2,
.login_container.login_sign-up .login_text.login_sign-up p,
.login_container.login_sign-up .login_img.login_sign-up img {
  transform: translateX(0);
}

/* BACKGROUND */

.login_container::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  height: 100vh;
  width: 300vw;
  transform: translate(35%, 0);
  background-image: linear-gradient(-45deg, #0a58ca 0%, var(--secondary-color) 100%);
  transition: 1s ease-in-out;
  z-index: 6;
  box-shadow: rgba(0, 0, 0, 0.login_35) 0px 5px 15px;
  border-bottom-right-radius: max(50vw, 50vh);
  border-top-left-radius: max(50vw, 50vh);
}

.login_container.login_sign-in::before {
  transform: translate(0, 0);
  right: 50%;
}

.login_container.login_sign-up::before {
  transform: translate(100%, 0);
  right: 50%;
}

/* RESPONSIVE */

@media only screen and (max-width: 425px) {

  .login_container::before,
  .login_container.login_sign-in::before,
  .login_container.login_sign-up::before {
      height: 100vh;
      border-bottom-right-radius: 0;
      border-top-left-radius: 0;
      z-index: 0;
      transform: none;
      right: 0;
  }

  /* .login_container.login_sign-in .login_col.login_sign-up {
      transform: translateY(100%);
  } */

  .login_container.login_sign-in .login_col.login_sign-in,
  .login_container.login_sign-up .login_col.login_sign-up {
      transform: translateY(0);
  }

  .login_content-row {
      align-items: flex-start !important;
  }

  .login_content-row .login_col {
      transform: translateY(0);
      background-color: unset;
  }

  .login_col {
      width: 100%;
      position: absolute;
      padding: 2rem;
      background-color: var(--white);
      border-top-left-radius: 2rem;
      border-top-right-radius: 2rem;
      transform: translateY(100%);
      transition: 1s ease-in-out;
  }

  .login_row {
      align-items: flex-end;
      justify-content: flex-end;
  }

  .login_form,
  .login_social-list {
      box-shadow: none;
      margin: 0;
      padding: 0;
  }

  .login_text {
      margin: 0;
  }

  .login_text p {
      display: none;
  }

  .login_text h2 {
      margin: .5rem;
      font-size: 2rem;
  }
}